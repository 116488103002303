import { styled } from '@mui/material/styles';
import { Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoginForm, useSnackbar } from 'standard';
import { post } from '../../utils/request';
import { isRelease, mainPath } from '../../config';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const submit = async ({ username, password, googleAuthCode }) => {
    // 发送登录请求
    const data = await post('/login', {
      username,
      password,
      googleAuthCode,
    });
    if (data.retcode === 0) {
      navigate(mainPath, { replace: true });
    } else {
      setSnackbar(data.retdesc, 'error')
    }
  }
  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm" >
          <StyledContent>
            <Box sx={{ padding: '30px', backgroundColor: 'white', boxShadow: '5px 5px 5px gray' }}>
              <LoginForm
                submit={submit}
                // requireGoogleAuthCode={isRelease()}
                logo={<Box
                  component="img"
                  src="LoginLogo.svg"
                  sx={{ height: 140, cursor: 'pointer' }}
                />}
              />
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
